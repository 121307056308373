















































































































































































import { dashboardViewModel } from '../viewmodels/dashboard-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { networks } from '@/constants'

@Observer
@Component({
  components: {
    HoverImage: () => import('@/modules/market/components/hover-image.vue'),
  },
})
export default class IdoPools extends Vue {
  @Inject() providers
  vm = dashboardViewModel

  disposers = []
  walletStore = walletStore
  isGrid = true
  tab = ''
  totalPage = 1
  page = 1
  itemsPerPage = 9

  networks = networks

  investHeaders = [
    { text: 'Project Investment', value: 'projectName', sortable: false },
    { text: '', value: 'chainId', sortable: false },
    { text: 'ID', value: 'nftId', sortable: false },
    { text: 'Locked', value: 'locked', sortable: false },
    { text: 'Unlocked', value: 'unlocked', sortable: false },
    { text: 'Price', value: 'price', sortable: false },
    { text: 'Total Value', value: 'totalValue', sortable: false },
    { text: 'Remaining Value', value: 'remainedValue', sortable: true },
    { text: 'Owner', value: 'owner', sortable: true },
  ]

  handleClick(item) {
    this.$router.push(`/investment-card/${item.keyInfo.id}?chainId=${item.poolStore.chainId}`)
  }

  calculateTotalValue(item) {
    if (!item.keyInfo || !item.poolStore) return 0
    return item.keyInfo.boughtAmounts.mulUnsafe(FixedNumber.from(item.poolStore.pool.ratio + ''))
  }
  calculateRemainingValue(item) {
    if (!item.keyInfo || !item.poolStore) return 0
    return item.keyInfo.remain.mulUnsafe(FixedNumber.from(item.poolStore.pool.ratio + ''))
  }
}
